*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  margin: 0;
  padding: 0;
}
body {
  line-height: 1.5;
  background-color: #fff;
  font-family: "Poppins", sans-serif;
  user-select: none;
}
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
a {
  text-decoration: none;
}
a,
button {
  cursor: pointer;
}
ul,
li {
  list-style: none;
}
input,
button,
textarea,
select {
  outline: none;
  border: none;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

.game {
  width: 100vw;
  height: 100vh;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.board {
  max-width: 500px;
  width: 100%;
  height: 500px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 25px;
}

.cell {
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "LXGW WenKai Mono TC", monospace;
  font-size: 90px;
  font-weight: bold;
}

.is-x {
  color: #e88d67;
}

.is-o {
  color: #81a263;
}

.btn {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #577b8d;
  color: #fff;
  border-radius: 5px;
  font-weight: bold;
  text-transform: capitalize;
}

.win {
  margin-top: 15px;
  padding-top: 10px;
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
  border-top: 1px solid #ccc;
}

.winner {
  color: #ad88c6;
}

.draw {
  color: #ee4e4e;
}

/* Mobile: width < 740px */
@media only screen and (max-width: 46.1875em) {
  .board {
    height: 400px;
  }
  .cell {
    font-size: 75px;
  }
}
